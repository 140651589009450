// -- Import your pages here..
const DTPHome = () => import('@/views/drs/document-template-prompt/DTPHome')
const DTPLists = () => import('@/views/drs/document-template-prompt/DTPLists')
const DTPCreate = () => import('@/views/drs/document-template-prompt/DTPCreate')
const DTPEdit = () => import('@/views/drs/document-template-prompt/DTPEdit')

export default 
{   
    path: '/:orgName/drs/document-template-prompt', 
    name: 'Document Template Prompt', 
    redirect: '/:orgName/drs/document-template-prompt',
    component: DTPHome,
    children: [
        {
            path: '/:orgName/drs/document-template-prompt', 
            name: 'Document Template Prompt Index', 
            meta: { label: "Index", auth: true, slug: 'document-template-prompt-list'},
            component: DTPLists,
        },
        {
            path: '/:orgName/drs/document-template-prompt/create', 
            name: 'Document Template Prompt Create', 
            meta: { label: "Create", auth: true, slug: 'create-document-template-prompt'},
            component: DTPCreate,
        },
        {
            path: '/:orgName/drs/document-template-prompt/:id', 
            name: 'Document Template Prompt Edit', 
            meta: { label: "View", auth: true, slug: 'view-document-template-prompt'},
            component: DTPEdit,
        }
    ]
}
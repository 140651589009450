// -- Import your pages here..
const TPSHome = () => import('@/views/drs/template-prompt-shared/TPSHome')
const TPSLists = () => import('@/views/drs/template-prompt-shared/TPSLists')
const TPSCreate = () => import('@/views/drs/template-prompt-shared/TPSCreate')
const TPSEdit = () => import('@/views/drs/template-prompt-shared/TPSEdit')

export default 
{   
    path: '/:orgName/drs/template-prompt-shared', 
    name: 'Template Prompt Shared', 
    redirect: '/:orgName/drs/template-prompt-shared',
    component: TPSHome,
    children: [
        {
            path: '/:orgName/drs/template-prompt-shared', 
            name: 'Template Prompt Shared Index', 
            meta: { label: "Index", auth: true, slug: 'template-prompt-shared-list'},
            component: TPSLists,
        },
        {
            path: '/:orgName/drs/template-prompt-shared/create', 
            name: 'Template Prompt Shared Create', 
            meta: { label: "Create", auth: true, slug: 'create-template-prompt-shared'},
            component: TPSCreate,
        },
        {
            path: '/:orgName/drs/template-prompt-shared/:id', 
            name: 'Template Prompt Shared Edit', 
            meta: { label: "View", auth: true, slug: 'view-template-prompt-shared'},
            component: TPSEdit,
        }
    ]
}